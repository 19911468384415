import './scss/main.scss';
import gsap from 'gsap';

gsap.from('.logo', { y: -100, duration: 0.75, ease: 'bounce(4)' });

(document.querySelectorAll('.button') as unknown as HTMLElement[]).forEach((button) => {
  button.addEventListener('click', () => {
    button.classList.add('clicked');
    setTimeout(() => {
      button.classList.remove('clicked');
    }, 100);
  });
});
